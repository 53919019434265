import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import ParticlesComponent from "assets/components/common/Particles";

//Main
const Intro = () => {

    // Coming Soon
    const handleClick = (event) => {
        event.preventDefault();
        alert('Coming Soon');
    };

    return (<>
        <div className="mainSec01">
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                speed={1000}
                autoplay={{
                   delay: 3500,
                   disableOnInteraction: false, // 사용자 상호작용 시 슬라이더 일시 정지 비활성
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className='main-slide'
            >
                <SwiperSlide className='slide01' style={{ backgroundImage: 'url(/img/visual/visual01.jpg)' }}>
                    <div className='slide-txt'>
                        <div>
                            <h2 className='mb20'>BITBOARD</h2>
                            <p style={{animationDelay: '0.7s'}}>A Space for Stars and Fans, <br/>Your Ultimate Online Fandom Service</p>
                            <a style={{animationDelay: '0.9s'}} href="https://w.bitboard.in/" target="blank" className="hashtag"><span>Platform w.bitboard.in</span></a>
                            {/* <a style={{animationDelay: '1.1s', marginTop: '1rem'}} href="https://ktoss-organization.gitbook.io/bitboards-whitepaper" target="blank" className="hashtag"><span>White Page</span></a> */}
                        </div>
                        <div className="img-token"></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='slide02' style={{ backgroundImage: 'url(/img/visual/visual02.jpg)' }}>
                    <div className='slide-txt'>
                        <div>
                            <h2 className='mb20'>BITBOARD</h2>
                            <p style={{animationDelay: '0.7s'}}>Vote for your star. <br/>Bitboard makes you rich.</p>
                            <div style={{animationDelay: '0.9s'}}>
                                <a href="https://tnetscan.in/" target="blank" className="hashtag"><span>Explorer tnetscan.in</span></a>
                                <a href="https://tnetw.in/ko/auth" target="blank" className="hashtag"><span>Tnet Wallet tnetw.in</span></a>
                            </div>
                        </div>
                    </div>
                    <div style={{animationDelay: '1.1s'}} className="img-earth"></div>
                </SwiperSlide>
            </Swiper>
            <ParticlesComponent/>
        </div>
    </>);
};

export default Intro;
