import React from 'react'

const NotFound = () => {
  return (
    <div className='not_found'>
      <h3>404</h3>
      <h4>Page Not Found</h4>
      <p>
        The page address is incorrect or the address of the requested page has been changed or deleted and cannot be found.
      </p>
        <a href="/">Home</a>      
    </div>
  )
}

export default NotFound