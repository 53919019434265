import React from 'react'

// Coming Soon
const handleClick = (event) => {
  event.preventDefault();
  alert('Coming Soon');
};

//Footer
const Footer = () => {
	return (
	<footer>
		<div className='container ft-grid'>
			<div className='ft-logo'><span className='sound_only'>bitboard</span></div>

			<div className='ft-policy'>
				<a href="/policy/service.pdf" target="_blank">Terms of Service</a>
				<a href="/policy/privacy.pdf" target="_blank">Privacy Policy</a>
			</div>

			<div className='ft-info'>
				<p>Contact us</p>
				<dl>
					<dt>E-maill</dt>
					<dd>bb@depl.at</dd>
				</dl>
			</div>

			<div className='sns'>
				<a href='https://x.com/bitboardtop' target='_blank' className='X'><span className='sound_only'>X</span></a>
				<a href='https://t.me/bitboard_Official' target='_blank' className='Telegram'><span className='sound_only'>Telegram</span></a>
				<a href='https://medium.com/bitboard-official' target='_blank' className='Medium'><span className='sound_only'>Medium</span></a>
			</div>
		</div>

		<div className="copyright">
			<div className='container'>Copyright © 2024 Bitboard. All Rights Reserved</div>
		</div>
	</footer>
)
}

export default Footer