import React from 'react'

const About = () => {

	return (<>
		<div id='secondPage' className="mainSec02">
			<div className='container'>
				<div className='left'>
					<h3>Platform</h3>
					<p>
					BB builds a fandom globalization ecosystem <br/>
					by introducing a platform that can expand the virtual space <br/>
					of the service so that BB can easily and conveniently pay <br/>
					for BB and decorate smart fandom spaces <br/>
					in various SNS and various entertainment services.
					</p>
				</div>
				<div className='right'>
					<div className='img'></div>
				</div>
			</div>
		</div>
	</>)
}

export default About