import React from 'react'

const Solutions = () => {

    return (<>
        <div id='thirdPage' className="mainSec03">
            <div className='sec-title'>
                <h3>Solutions</h3>
            </div>

            <ul className='solution-list'>
                <li>DECENTRALIZED <br/>+ <br/>RANKING SERVICE SITE</li>
                <li style={{animationDelay: '0.7s'}}>ENHANCING FANDOM <br/>CULTURE WITH FUN AND <br/>FINANCIAL MANAGEMENT</li>
                <li style={{animationDelay: '0.9s'}}>VOTING ON STAR RANKINGS <br/>AND BLOCKCHAIN RECEIVING <br/>AIRDROPS AS A REWARD</li>
                <li style={{animationDelay: '1.1s'}}>RANKING ARE <br/>UPDATED IN REAL TIME</li>
                <li style={{animationDelay: '1.3s'}}>CANCELLING VOTES <br/>AND WITHDRAWING FUNDS</li>
                <li style={{animationDelay: '1.5s'}}>IF THERE ARE 30 NEW <br/>REGISTANTS IN A  GATEGORY, <br/>IT WILL BE RESITERED AS A NEW FIELD</li>
                <li style={{animationDelay: '1.7s'}}>REWORDING FANS <br/>FOR SUPPORTING <br/>THEIR FAVORITE STARS</li>
                <li style={{animationDelay: '1.9s'}}>3 BILLION BB DEPOSITED <br/>IN THE MINING POOL <br/>BY THE BITBOARD BOT, WHICH <br/>ACTS AS A DECENTRALIZED BANK</li>
            </ul>
        </div>
    </>)
}

export default Solutions