import React from 'react'

const Technical = () => {

  return (<>
    <div id='sixthPage' className="mainSec06">
        <div className='sec-title'>
            <h3>Technical</h3>
        </div>
        <ul className='tech-list'>
            <li>
                <div>
                    <div className='title'>Decentralized Network Structure</div>
                    <p>Ensures network stability and scalability through efficient data transmission and verification.</p>
                </div>
            </li>
            <li>
                <div style={{animationDelay: '0.7s'}}>
                    <div className='title'>Block Propagation Mechanism</div>
                    <p>Optimizes block propagation to enhance transparency and reliability.</p>
                </div>
            </li>
            <li>
                <div style={{animationDelay: '0.9s'}}>
                    <div className='title'>Smart Contract and DApp Support</div>
                    <p>Supports the development of smart contracts and decentralized applications (DApps) using Ethereum-compatible EVM and Solidity.</p>
                </div>
            </li>
        </ul>
    </div>
  </>)
}

export default Technical