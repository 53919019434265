import React, { useState, useEffect } from 'react';
import { FullpageContainer, FullpageSection } from '@shinyongjun/react-fullpage';
import '@shinyongjun/react-fullpage/css';
import { Intro , About , Solutions , Wallet, Ecosystem, Technical, UseCases } from 'pages';
import Footer from './common/Footer';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';


export default function FullpageWrapper() {
	const [activeIndex, setActiveIndex] = useState(0);

	// 헤더 컬러 반전시키기
	useEffect(() => {
		const headerElement = document.getElementById('header');
		if (headerElement) {
			if (activeIndex === 7) {
				headerElement.classList.add('reverse');
			} else {
				headerElement.classList.remove('reverse');
			}
		}
	}, [activeIndex]);

	// Coming Soon
    const handleClick = (event) => {
        event.preventDefault();
        alert('Coming Soon');
    };

	// Toggle Launch
    const [isOpen, setIsOpen] = useState(false);
    const handleDrawer = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        const handleEscKeyPress = (e) => {
            if (e.keyCode === 27 && isOpen) {setIsOpen(false);}
        };

        if (isOpen) {
            document.body.style.setProperty("overflow", "hidden");
        } else {
            document.body.style.removeProperty("overflow");
        }

        document.addEventListener("keydown", handleEscKeyPress);

        return () => {
            document.removeEventListener("keydown", handleEscKeyPress);
        };
    }, [isOpen]);


	return (<>
		<header id='header'>
            <div className='container'>
                <h1><a href="#firstPage"><span className='sound_only'>bitboard</span></a></h1>

                <nav id="gnb" className="gnb">
                    <ul>
                        <li onClick={() => setActiveIndex(1)}>Platform</li>
                        <li onClick={() => setActiveIndex(2)}>Solutions</li>
                        <li onClick={() => setActiveIndex(3)}>Wallet</li>
                        <li onClick={() => setActiveIndex(4)}>Ecosystem</li>
                        <li onClick={() => setActiveIndex(5)}>Technical</li>
                        <li onClick={() => setActiveIndex(6)}>Use Cases</li>
                    </ul>
                </nav>
                
                <div className='right'>
                    <a href='https://ktoss-organization.gitbook.io/bitboards-whitepaper' target='blank' className="btn-gitbook"><span>Gitbook</span></a>
                    <button type="button" className="btn-launch" onClick={handleDrawer}><span>Launch App</span></button>
                </div>
            </div>
        </header>

        <div className={`launch-container ${isOpen ? "open" : ""}`}>
            <div className='launch-head'>
                <div className='title'>bitboard</div>
                <button type='button' className='btn-close' onClick={() => setIsOpen(false)}><span className='sound_only'>close</span></button>
            </div>
            <div className='launch-content'>
                <nav className="snb">
                    <ul>
                        <li><a href="#secondPage" onClick={() => setIsOpen(false)}><span>Platform</span></a></li>
                        <li><a href="#thirdPage" onClick={() => setIsOpen(false)}><span>Solutions</span></a></li>
                        <li><a href="#fourthPage" onClick={() => setIsOpen(false)}><span>Wallet</span></a></li>
                        <li><a href="#fifthPage" onClick={() => setIsOpen(false)}><span>Ecosystem</span></a></li>
                        <li><a href="#sixthPage" onClick={() => setIsOpen(false)}><span>Technical</span></a></li>
                        <li><a href="#seventhPage" onClick={() => setIsOpen(false)}><span>Use Cases</span></a></li>
                    </ul>
                </nav>
                <div className='accordion-container'>
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <div className='title'>Bitboard Platform</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='content'>
                                <a href='https://w.bitboard.in/' target='blank' className='link-platform bbtoken'><p>Bitboard Token <br/><small>Bitboard Chart</small></p></a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary>
                            <div className='title'>Tnet</div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='content'>
                                <a href='https://tnetscan.in/' target='blank' className='link-platform tnetscan'><p>Explorer <br/></p></a>
                                <a href='https://tnetw.in/ko/auth' target='blank' className='link-platform tnetwallet'><p>Tnet Wallet <br/></p></a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>

		<FullpageContainer activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
          <FullpageSection name="firstPage"><div className="section sec01"><Intro/></div></FullpageSection>
          <FullpageSection name="secondPage"><div className="section sec02"><About/></div></FullpageSection>
          <FullpageSection name="thirdPage"><div className="section sec03"><Solutions/></div></FullpageSection>
          <FullpageSection name="fourthPage"><div className="section sec04"><Wallet/></div></FullpageSection>
          <FullpageSection name="fifthPage"><div className="section sec05"><Ecosystem/></div></FullpageSection>
          <FullpageSection name="sixthPage"><div className="section sec06"><Technical/></div></FullpageSection>
          <FullpageSection name="seventhPage"><div className="section sec07"><UseCases/></div></FullpageSection>
          <FullpageSection name="footer" isAutoHeight><div className="section sec08 fp-auto-height"><Footer/></div></FullpageSection>
		</FullpageContainer>
	</>);
}