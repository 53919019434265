import React from 'react'

const Wallet = () => {

  return (<>
    <div id="fourthPage" className="mainSec04">
        <div className='sec-title'>
            <h3>Tnet Wallet</h3>
            <p style={{animationDelay: '0.7s'}}>
                Use a variety of functions and services through convenient connection to the deposit and withdrawal system platform<br/>
                Swap service that allows you to exchange and purchase coins (token) <br/>
                Tnet-based all tokens and coins are linked in one place
            </p>
        </div>
        <div style={{animationDelay: '0.9s'}} className='phone'><span className='sound_only'>iPhone 15 Pro</span></div>
        
    </div>
  </>)
}

export default Wallet