import React from "react";
import FullpageWrapper from "assets/components/FullpageWrapper";
import useIsMobile from "assets/hooks/useIsMobile";
import Header from "assets/components/common/Header";
import Footer from "assets/components/common/Footer";
import { Intro , About , Solutions, Wallet, Ecosystem, Technical, UseCases } from 'pages';

//Main
const Home = () => {
    const isMobile = useIsMobile();


    return (<>

        <div className='mainPage'>
        {
            isMobile?<>
                <Header/>
                <Intro/>
                <About/>
                <Solutions/>
                <Wallet/>
                <Ecosystem/>
                <Technical/>
                <UseCases/>
                <Footer/>
            </>:<FullpageWrapper/>
        }
        </div>
    </>);
};

export default Home;
