import React from 'react'

const UseCases = () => {

  return (<>
    <div id='seventhPage' className="mainSec07">
        <div className='sec-title'>
            <h3>Use Cases</h3>
        </div>
        <ol className='case-list'>
            <li>
                <div>1. DeFi Services</div>
                <ul>
                    <li>Decentralized Exchange (DEX)</li>
                    <li>Lending and Borrowing Platform</li>
                    <li>Stablecoins</li>
                </ul>
            </li>
            <li>
                <div style={{animationDelay: '0.7s'}}>2. Supply Chain Management</div>
                <ul>
                    <li>Transparency</li>
                    <li>Tracking</li>
                    <li>Counterfeit issues</li>
                </ul>
            </li>
            <li>
                <div style={{animationDelay: '0.9s'}}>3. Digital Asset Management</div>
                <ul>
                    <li>Creation</li>
                    <li>Management</li>
                    <li>Trading</li>
                </ul>
            </li>
            <li>
                <div style={{animationDelay: '1.1s'}}>4. Healthcare</div>
                <ul>
                    <li>Patient medical records</li>
                    <li>Privacy</li>
                    <li>Data sharing</li>
                </ul>
            </li>
            <li>
                <div style={{animationDelay: '1.3s'}}>5. Public Services</div>
                <ul>
                    <li>Trust</li>
                    <li>Electronic voting</li>
                    <li>Corruption prevention</li>
                </ul>
            </li>
        </ol>
        
    </div>
  </>)
}

export default UseCases