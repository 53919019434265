// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, NotFound, About, Solutions, Wallet, Ecosystem, Technical, UseCases } from "./pages";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          {/* 정확한 경로로 매칭 */}
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Solutions" element={<Solutions />} />
          <Route path="/Wallet" element={<Wallet />} />
          <Route path="/Ecosystem" element={<Ecosystem />} />
          <Route path="/Technical" element={<Technical />} />
          <Route path="/UseCases" element={<UseCases />} />

          {/* 404 페이지 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
