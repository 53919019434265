import React from 'react'

const Ecosystem = () => {

  return (<>
    <div id='fifthPage' className="mainSec05">
        <div className='sec-title'>
            <h3>Ecosystem</h3>
        </div>
        <ul className='eco-list'>
            <li>
                <div className='head'>
                    <p>Technical Overview</p>
                    <div className='title'>Consensus Algorithm</div>
                </div>
                <div className='content'>Tnet uses a committee-based consensus algorithm that requires signatures from over two-thirds of its members. This system is designed based on Byzantine Fault Tolerance (BFT).</div>
            </li>
            <li style={{animationDelay: '0.7s'}}>
                <div className='head'>
                    <p>Technical Overview</p>
                    <div className='title'>EVM and Solidity</div>
                </div>
                <div className='content'>Tnet runs the Ethereum Virtual Machine (EVM) and supports Solidity contracts. making it friendly for existing Ethereum developers.</div>
            </li>
            <li style={{animationDelay: '0.9s'}}>
                <div className='head'>
                    <p>Technical Overview</p>
                    <div className='title'>Service Chain</div>
                </div>
                <div className='content'>It provides a dedicated execution environment for applications, featuring high TPS (transactions per second)
and configurable network policies. It can operate independently.</div>
            </li>
            <li style={{animationDelay: '1.1s'}}>
                <div className='head'>
                    <p>Use cases - DeFi Services</p>
                    <div className='title'>Decentralized Exchange (DEX)</div>
                </div>
                <div className='content'>Enables real-time asset exchange while minimizing slippage and transaction failures, providing users with full control over their assets.</div>
            </li>
            <li style={{animationDelay: '1.3s'}}>
                <div className='head'>
                    <p>Use cases - DeFi Services</p>
                    <div className='title'>Lending and Borrowing Platform</div>
                </div>
                <div className='content'>Facilitates automated lending and repayment through smart contracts, with automatic liquidation based on the value changes of collateral assets.</div>
            </li>
            <li style={{animationDelay: '1.5s'}}>
                <div className='head'>
                    <p>Use cases - DeFi Services</p>
                    <div className='title'>Stablecoins</div>
                </div>
                <div className='content'>Digital assets pegged to fiat currencies, ensuring minimal price volatility, and guaranteeing transparency and reliability.</div>
            </li>
        </ul>
    </div>
  </>)
}

export default Ecosystem